import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () => import('../views/Main.vue'),
        children: [{
                meta: {
                    title: '仪表盘',
                    auth: true,
                    active: '1',
                    keepAlive: false
                },
                path: "/",
                name: "home.index",
                component: resolve => require(['@/views/home/Index'], resolve)
            }, {
                meta: {
                    title: '仪表盘',
                    auth: true,
                    active: '1',
                    keepAlive: false
                },
                path: "/achievement",
                name: "home.achievement",
                component: resolve => require(['@/views/home/achievement'], resolve)
            },
            {
                meta: {
                    title: '客服导入',
                    auth: true,
                    active: '2',
                },
                path: "/orders/imports",
                name: "orders.imports",
                component: resolve => require(['@/views/orders/Imports'], resolve)
            },
            {
                meta: {
                    title: '销售订单管理',
                    auth: true,
                    active: '2',
                    permission: 'orders.index'
                },
                path: "/orders",
                name: "orders.index",
                component: resolve => require(['@/views/orders/Index'], resolve)
            },
            {
                meta: {
                    title: '承诺发货订单',
                    auth: true,
                    active: '2',
                    permission: 'promiseship.index'
                },
                path: "/promiseship",
                name: "promiseship.index",
                component: resolve => require(['@/views/promiseship/Index'], resolve)
            },
            {
                meta: {
                    title: '销售订单详情',
                    auth: true,
                    active: '2',
                    permission: 'orders.show'
                },
                path: "/orders/:id(\\d+)",
                name: "orders.show",
                component: resolve => require(['@/views/orders/Show'], resolve)
            }, {
                meta: {
                    title: '工作便签',
                    auth: true,
                    active: '2',
                    permission: 'note.index'
                },
                path: "/works",
                name: "note.index",
                component: resolve => require(['@/views/note/index'], resolve)
            },
            {
                meta: {
                    title: '未处理便签',
                    auth: true,
                    active: '2',
                    permission: 'unprocessed.index'
                },
                path: "/unprocessed",
                name: "unprocessed.index",
                component: resolve => require(['@/views/unprocessed/index'], resolve)
            }, {
                meta: {
                    title: '销售订单管理',
                    auth: true,
                    active: '2',
                    permission: 'orders.index'
                },
                path: "/orders",
                name: "orders.index",
                component: resolve => require(['@/views/orders/Index'], resolve)
            },
            {
                meta: {
                    title: '物料信息管理',
                    auth: true,
                    active: '4',
                    permission: 'items.index'
                },
                path: "/items",
                name: "items.index",
                component: resolve => require(['@/views/items/Index'], resolve)
            },
            {
                meta: {
                    title: '物料信息管理',
                    auth: true,
                    active: '4',
                    permission: 'items.import'
                },
                path: "/items/import",
                name: "items.import",
                component: resolve => require(['@/views/items/Import'], resolve)
            }, {
                meta: {
                    title: '物料信息管理',
                    auth: true,
                    active: '4',
                    permission: 'items.imports'
                },
                path: "/items/imports",
                name: "items.imports",
                component: resolve => require(['@/views/items/Imports'], resolve)
            },

            {
                meta: {
                    title: '供货商管理',
                    auth: true,
                    active: '4',
                    permission: 'suppliers.index'
                },
                path: "/suppliers",
                name: "suppliers.index",
                component: resolve => require(['@/views/suppliers/Index'], resolve)
            },

            {
                meta: {
                    title: '商品品牌管理',
                    auth: true,
                    active: '4',
                    permission: 'brands.index'
                },
                path: "/brands",
                name: "brands.index",
                component: resolve => require(['@/views/brands/Index'], resolve)
            },
            {
                meta: {
                    title: '商品分类管理',
                    auth: true,
                    active: '4',
                    permission: 'categories.index'
                },
                path: "/categories",
                name: "categories.index",
                component: resolve => require(['@/views/categories/Index'], resolve)
            },
            {
                meta: {
                    title: '商品分类管理',
                    auth: true,
                    active: '4',
                    permission: 'categories_qudao.index'
                },
                path: "/categories_qudao",
                name: "categories_qudao.index",
                component: resolve => require(['@/views/categories_qudao/Index'], resolve)
            },
            {
                meta: {
                    title: '权限角色管理',
                    auth: true,
                    active: '8',
                    permission: 'roles.index'
                },
                path: "/roles",
                name: "roles.index",
                component: resolve => require(['@/views/roles/Index'], resolve)
            },
            {
                meta: {
                    title: '权限明细管理',
                    auth: true,
                    active: '8',
                    permission: 'permissions.index'
                },
                path: "/permissions",
                name: "permissions.index",
                component: resolve => require(['@/views/permissions/Index'], resolve)
            },
            {
                meta: {
                    title: '系统运行日志',
                    auth: true,
                    active: '8',
                    permission: 'loggings.index'
                },
                path: "/loggings",
                name: "loggings.index",
                component: resolve => require(['@/views/loggings/Index'], resolve)
            },
            {
                meta: {
                    title: '消费者信息',
                    auth: true,
                    active: '7',
                    permission: 'customers.index'
                },
                path: "/customers",
                name: "customers.index",
                component: resolve => require(['@/views/customers/Index'], resolve)
            },
            {
                meta: {
                    title: '批量导入',
                    auth: true,
                    active: '7',
                    permission: 'customers.Import'
                },
                path: "/customers/Import",
                name: "customers.Import",
                component: resolve => require(['@/views/customers/Import'], resolve)
            },
            {
                meta: {
                    title: '消费者信息',
                    auth: true,
                    active: '7',
                    permission: 'customers.map'
                },
                path: "/customers/map",
                name: "customers.map",
                component: resolve => require(['@/views/customers/Map'], resolve)
            },
            {
                meta: {
                    title: '授权门店管理',
                    auth: true,
                    active: '8',
                    permission: 'stores.index'
                },
                path: "/stores",
                name: "stores.index",
                component: resolve => require(['@/views/stores/Index'], resolve)
            },
            {
                meta: {
                    title: '加工单管理',
                    auth: true,
                    active: '3',
                    permission: 'machining.index'
                },
                path: "/machining",
                name: "machining.index",
                component: resolve => require(['@/views/machining/Index'], resolve)
            },
            {
                meta: {
                    title: '创建加工单',
                    auth: true,
                    active: '3',
                    permission: 'machining.create'
                },
                path: "/machining/create/:id(\\d+)",
                name: "machining.create",
                component: resolve => require(['@/views/machining/Create'], resolve)
            },
            {
                meta: {
                    title: '加工单管理',
                    auth: true,
                    active: '3',
                    permission: 'machining.show'
                },
                path: "/machining/:id(\\d+)",
                name: "machining.show",
                component: resolve => require(['@/views/machining/Show'], resolve)
            },
            {
                meta: {
                    title: '物流列表',
                    auth: true,
                    active: '3',
                    permission: 'logistics.index'
                },
                path: "/logistics",
                name: "logistics.index",
                component: resolve => require(['@/views/logistics/index'], resolve)
            }, {
                meta: {
                    title: '添加物流',
                    auth: true,
                    active: '3',
                    permission: 'logistics.add'
                },
                path: "/logistics/add",
                name: "logistics.add",
                component: resolve => require(['@/views/logistics/add'], resolve)
            },
            {
                meta: {
                    title: '编辑加工单',
                    auth: true,
                    active: '3',
                    permission: 'services.index'
                },
                path: "/machining/:id(\\d+)/update",
                name: "machining.update",
                component: resolve => require(['@/views/machining/Update'], resolve)
            },
            {
                meta: {
                    title: '打印加工单',
                    auth: true,
                    active: '3'
                },
                path: "/machining/:id(\\d+)/print",
                name: "machining.print",
                component: resolve => require(['@/views/machining/Print'], resolve)
            },
            {
                meta: {
                    title: '加工单待收',
                    auth: true,
                    active: '3'
                },
                path: "/machining/receive",
                name: "machining.receive",
                component: resolve => require(['@/views/machining/Receive'], resolve)
            },
            {
                meta: {
                    title: '加工单售后',
                    auth: true,
                    active: '3',
                    permission: 'machining.service'
                },
                path: "/services",
                name: "services.index",
                component: resolve => require(['@/views/services/Index'], resolve)
            },

            {
                meta: {
                    title: '加工单售后',
                    auth: true,
                    active: '3',
                    permission: 'services.show'
                },
                path: "/services/:id(\\d+)",
                name: "services.show",
                component: resolve => require(['@/views/services/Show'], resolve)
            },

            {
                meta: {
                    title: '管理员管理',
                    auth: true,
                    active: '8',
                    permission: 'users.show'
                },
                path: "/users",
                name: "users.index",
                component: resolve => require(['@/views/users/Index'], resolve)
            },
            {
                meta: {
                    title: '个人信息',
                    auth: true,
                    active: '8'
                },
                path: "/infocenter",
                name: "infocenter.index",
                component: resolve => require(['@/views/infocenter/Index'], resolve)
            },
            {
                meta: {
                    title: '绑定微信',
                    auth: true,
                    active: '8'
                },
                path: "/infocenter/wechat",
                name: "infocenter.wechat",
                component: resolve => require(['@/views/infocenter/Wechat'], resolve)
            },
            {
                meta: {
                    title: '渠道商品管理',
                    auth: true,
                    active: '4',
                    permission: 'skus.index'
                },
                path: "/skus",
                name: "skus.index",
                component: resolve => require(['@/views/skus/Index'], resolve)
            },
            {
                meta: {
                    title: '渠道商品管理',
                    auth: true,
                    active: '4'
                },
                path: "/skus/import",
                name: "skus.import",
                component: resolve => require(['@/views/skus/Import'], resolve)
            },
            {
                meta: {
                    title: '同步记录管理',
                    auth: true,
                    active: '8',
                    permission: 'sync.index'
                },
                path: "/sync",
                name: "sync.index",
                component: resolve => require(['@/views/sync/Index'], resolve)
            },
            {
                meta: {
                    title: '营销短信管理',
                    auth: true,
                    active: '8'
                },
                path: "/sms",
                name: "sms.index",
                component: resolve => require(['@/views/sms/Index'], resolve)
            },
            {
                meta: {
                    title: '订单售后服务管理',
                    auth: true,
                    active: '2',
                    permission: 'sales.index'
                },
                path: "/sales",
                name: "sales.index",
                component: resolve => require(['@/views/sales/Index'], resolve)
            },
            {
                meta: {
                    title: '订单售后服务管理',
                    auth: true,
                    active: '2',
                    permission: 'sales.show'
                },
                path: "/sales/:id(\\d+)",
                name: "sales.show",
                component: resolve => require(['@/views/sales/Show'], resolve)
            },
            {
                meta: {
                    title: '标签管理',
                    auth: true,
                    active: '3'
                },
                path: "/tags",
                name: "tags.index",
                component: resolve => require(['@/views/tags/Index'], resolve)
            },
            {
                meta: {
                    title: '售后原因',
                    auth: true,
                    active: '3'
                },
                path: "/reason",
                name: "reason.index",
                component: resolve => require(['@/views/reason/Index'], resolve)
            },
            {
                meta: {
                    title: '镜架交付厂家',
                    auth: true,
                    active: '3'
                },
                path: "/framedelivery",
                name: "framedelivery.index",
                component: resolve => require(['@/views/framedelivery/index'], resolve)
            }, {
                meta: {
                    title: '镜架签收记录',
                    auth: true,
                    active: '3'
                },
                path: "/mirrorframereceipt",
                name: "mirrorframereceipt.Index",
                component: resolve => require(['@/views/mirrorframereceipt/Index'], resolve)
            },

            {

                meta: {
                    title: '毛坯镜片收货',
                    auth: true,
                    active: '3'
                },
                path: "/Recordreceivinglenses",
                name: "Recordreceivinglenses.Index",
                component: resolve => require(['@/views/Recordreceivinglenses/Index'], resolve)
            },
            {

                meta: {
                    title: '成品镜片收货',
                    auth: true,
                    active: '3'
                },
                path: "/Finishedlensreceivingrecord",
                name: "Finishedlensreceivingrecord.Index",
                component: resolve => require(['@/views/Finishedlensreceivingrecord/Index'], resolve)
            },

            {
                meta: {
                    title: '镜架交付厂家',
                    auth: true,
                    active: '3'
                },
                path: "/lensprocessing",
                name: "lensprocessing.index",
                component: resolve => require(['@/views/lensprocessing/index'], resolve)
            },

            {
                meta: {
                    title: '质检合格',
                    auth: true,
                    active: '3'
                },
                path: "/Qualityinspection",
                name: "Qualityinspection.Index",
                component: resolve => require(['@/views/Qualityinspection/Index'], resolve)
            }, {
                meta: {
                    title: '质检不合格',
                    auth: true,
                    active: '3'
                },
                path: "/unqualifiedqualityinspection",
                name: "unqualifiedqualityinspection.Index",
                component: resolve => require(['@/views/unqualifiedqualityinspection/Index'], resolve)
            },
            {
                meta: {
                    title: '财务管理',
                    auth: true,
                    active: '5',
                    permission: 'payables.index'
                },
                path: "/finance",
                name: "finance.index",
                component: resolve => require(['@/views/finance/Index'], resolve)
            },
            {
                meta: {
                    title: '账单详情',
                    auth: true,
                    active: '5',
                    permission: 'payables.show'
                },
                path: "/finance/:id(\\d+)",
                name: "finance.show",
                component: resolve => require(['@/views/finance/Show'], resolve)
            },
            {
                meta: {
                    title: '售后账款',
                    auth: true,
                    active: '5'
                },
                path: "/finance/discards",
                name: "finance.discards",
                component: resolve => require(['@/views/finance/Discards'], resolve)
            },
            {
                meta: {
                    title: '财务统计',
                    auth: true,
                    active: '5',
                    permission: 'finance.index'
                },
                path: "/finance/statistics",
                name: "finance.statistics",
                component: resolve => require(['@/views/finance/Statistics'], resolve)
            },
            {
                meta: {
                    title: '订单数据报表',
                    auth: true,
                    permission: 'statistic.order',
                    active: '6'
                },
                path: "/statistics",
                name: "statistics.index",
                component: resolve => require(['@/views/statistics/Index'], resolve)
            },
            {
                meta: {
                    title: '订单地域报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.orderarea'
                },
                path: "/statisticsline",
                name: "statisticsline.index",
                component: resolve => require(['@/views/statisticsline/Index'], resolve)
            },
            {
                meta: {
                    title: '订单复购报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.orderrepur'
                },
                path: "/statisticsrepurchase",
                name: "statisticsrepurchase.index",
                component: resolve => require(['@/views/statisticsrepurchase/Index'], resolve)
            },
            {
                meta: {
                    title: '商品品牌报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.goodbrand'
                },
                path: "/statistibrandtable",
                name: "statistibrandtable.index",
                component: resolve => require(['@/views/statistibrandtable/Index'], resolve)
            },
            {
                meta: {
                    title: '商品销售报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.goodsale'
                },
                path: "/statisticssale",
                name: "statisticssale.index",
                component: resolve => require(['@/views/statisticssale/Index'], resolve)
            },
            {
                meta: {
                    title: '商品销售报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.userarea'
                },
                path: "/statisticsregion",
                name: "statisticsregion.index",
                component: resolve => require(['@/views/statisticsregion/Index'], resolve)
            },
            {
                meta: {
                    title: '客户新增统计报表',
                    auth: true,
                    active: '6',
                    permission: 'statistic.useradd'
                },
                path: "/statisticsaddition",
                name: "statisticsaddition.index",
                component: resolve => require(['@/views/statisticsaddition/Index'], resolve)
            },
            //statisticsaddition
        ]
    },
    {
        meta: {
            title: '登录系统',
            auth: false,
            active: '0'
        },
        path: '/basics/signin',
        name: "basics.signin",
        component: resolve => require(['@/views/basics/Signin'], resolve)
    },

    {
        meta: {
            title: '销售订单打印',
            auth: true,
            active: '2'
        },
        path: "/orders/:id(\\d+)/print",
        name: "orders.print",
        component: resolve => require(['@/views/orders/Print'], resolve)
    }, {
        meta: {
            title: '销售订单电子打印',
            auth: true,
            active: '2'
        },
        path: "/orders/:id(\\d+)/eprint",
        name: "orders.eprint",
        component: resolve => require(['@/views/orders/Eprint'], resolve)
    },

    {
        meta: {
            title: '打印',
            auth: true,
            active: '2'
        },
        path: "/services/Afterrinting",
        name: "services.Afterrinting",
        component: resolve => require(['@/views/services/Afterrinting'], resolve)
    },
        {
        meta: {
            title: '客服导入',
            auth: true,
            active: '2',
        },
        path: "/orders/imports",
        name: "orders.imports",
        component: resolve => require(['@/views/orders/Imports'], resolve)
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router