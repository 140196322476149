import request from '@/utils/request'


/*
| 订单相关操作.
*/
export function search(params) {
    return request.get(`api/search`, params, null)
}

export function ordersIndex(params) {
    return request.get(`api/orders`, params, 'orders.index')
}

export function ordersShow(id) {
    return request.get(`api/orders/${id}`, {}, 'orders.show')
}

export function ordersChannel(id) {
    return request.post(`api/orders/channel`, {
        id
    }, 'orders.channel')
}

export function ordersPrint(id) {
    return request.post(`api/orders/${id}/print`, {}, 'orders.print')
}

export function ordersRemark(id, params) {
    return request.post(`api/orders/${id}/remark`, params, 'orders.remark')
}

export function ordersConsignee(id, params) {
    return request.post(`api/orders/${id}/consignee`, params, 'orders.consignee')
}

export function ordersExports(params) {
    return request.post(`api/orders/exports`, params, 'orders.exports')
}

export function ordersSync(params) {
    return request.post(`api/orders/sync`, params, 'orders.sync')
}

export function ordersSales(id, params) {
    return request.post(`api/orders/${id}/sales`, params, 'orders.sales')
}

export function ordersOutbound(id) {
    return request.post(`api/orders/${id}/outbound`, {}, 'orders.outbound')
}
export function jdreport(order,jdreport) {
    return request.post(`api/orders/${order}/jdreport`,jdreport, 'orders.outbound')
}
export function costIndex(id, params) {
    return request.post(`api/cost/${id}`, params, 'cost.index')
}

export function costSync(id) {
    return request.post(`api/cost/${id}/sync`, {}, 'cost.sync')
}

export function ordersDeliver(id, params) {
    return request.post(`api/orders/${id}/deliver`, params, 'orders.deliver')
}
export function ordersdeliverPdd(id, params) {
    return request.post(`api/orders/${id}/deliverPdd`, params, 'orders.deliver')
}
export function ordersWaybill(id, params) {
    return request.post(`api/orders/${id}/waybill`, params, 'orders.waybill')
}
export function ordersElectron(id) {
    return request.post(`api/orders/${id}/electron`, {}, 'orders.electron')
}
// 首页
export function home() {
    return request.get(`api/home`, {}, 'home')
}
export function homebrand(params) {
    return request.get(`api/home/brand`, params, 'home.brand')
}
export function homecustomer(params) {
    return request.get(`api/home/customer`, params, 'home.brand')
}

/*
| 品牌相关操作.
*/
export function brandsIndex(params) {
    return request.get(`api/brands`, params, 'brands.index')
}

export function brandsStore(params) {
    return request.post(`api/brands`, params, 'brands.store')
}

export function brandsUpdate(id, params) {
    return request.put(`api/brands/${id}`, params, 'brands.update')
}

/*
| 分类相关操作.
*/

export function categoriesIndex(params) {
    return request.get(`api/categories`, params, 'categories.index')
}
export function SkuCategory(params) {
    return request.get(`api/skuCategory`, params, 'categories.index')
}
export function cateSkuCategory(params) {
    return request.post(`api/skuCategory`, params, 'categories.index')
}

export function SkuCategoryUpdate(id, params) {
    return request.put(`api/skuCategory/${id}`, params, 'categories.update')
}
export function categoriesStore(params) {
    return request.post(`api/categories`, params, 'categories.store')
}


export function categoriesUpdate(id, params) {
    return request.put(`api/categories/${id}`, params, 'categories.update')
}
/*工作便签*/

export function noteWorkss(params) {

    return request.get('api/works', params)
}
export function addworksSelf(params) {
    return request.post(`api/works/self`, params, null)
}
export function noteWorks(params) {
    return request.get(`api/works`, params, null)
}
export function worksremind() {
    return request.get(`api/works/remind`, {}, null)
}
export function infocenterworks(id) {
    return request.delete(`api/works/${id}`, {}, null)
}
export function addworks(params) {
    return request.post(`api/works`, params, null)
}


export function worksstate(params) {
    return request.post(`api/works/state`, params, null)
}
export function worksstatus(params) {
    return request.post(`api/works/status`, params, null)
}
/*物流管理 */
export function waybill(params) {
    return request.get(`api/waybill`, params, null)
}
export function waybillcreate(params) {
    return request.post(`api/waybill/create`, params, null)
}
export function waybilldelete(id) {
    return request.delete(`api/waybill/${id}`, {}, null)
}



/*
| 个人中心.
*/
export function infocenterIndex() {
    return request.get(`api/infocenter`, {}, null)
}

export function infocenterProfile(params) {
    return request.post(`api/infocenter`, params, 'infocenter.profile')
}

export function infocenterWechat() {
    return request.get(`api/infocenter/wechat`, {}, 'infocenter.wechat')
}

export function infocenterDestroy() {
    return request.post(`api/infocenter/destroy`, {}, 'infocenter.destroy')
}

/*
| 微信授权相关.
*/
export function wechatIndex() {
    return request.get(`api/wechat`, {}, 'wechat.index')
}

/*
| 物料相关操作.
*/
export function itemsIndex(params) {
    return request.get('api/items', params, 'items.index')
}

export function itemsStore(params) {
    return request.post(`api/items`, params, 'items.store')
}

export function itemsUpdate(id, params) {
    return request.put(`api/items/${id}`, params, 'items.update')
}

export function itemsImport(params) {
    return request.post(`api/items/import`, params, 'items.import')
}
export function ordersImport(params) {
    return request.post(`api/orders/import`, params, 'items.import')
}

export function importCustomers(params) {
    
    return request.post(`api/items/importCustomers`, params, 'items.import')
}
export function customersImport(params) {
    return request.post(`api/customers/import`, params, 'items.import')
}


export function itemsSale(params) {
    return request.post(`api/items/sale`, params, 'items.sale')
}

export function itemsBinds(id, params) {
    return request.post(`api/items/${id}/binds`, params, 'items.binds')
}

export function itemsSkus(id, params) {
    return request.post(`api/items/${id}/skus`, params, 'items.skus')
}

export function itemsAttr(id) {
    return request.get(`api/items/${id}/attr`, {}, 'items.attr')
}

export function itemsDel(id) {
    return request.post(`api/items/${id}/delete`, {}, 'items.delete')
}

export function ItemAttrRange(barcode) {
    return request.post(`api/items/range`, {
        barcode
    }, 'items.range')
}

export function ItemAttrRangeZhujing(barcode, sph) {
    return request.post(`api/items/range`, {
        barcode,
        sph
    }, 'items.range')
}

export function itemExports(params) {
    return request.post(`api/items/exports`, params, 'items.exports')
}
export function handleexports(params) {
    return request.post(`api/items/exports_sku`, params, 'items.exports')
}

/*
| 日志相关操作.
*/
export function loggingsIndex(params) {
    return request.get('api/loggings', params, 'loggings.index')
}

/*
| 加工单相关操作.
*/
export function machiningIndex(params) {
    return request.get('api/machining', params, 'machining.index')
}
export function machiningTeacherhistory(params) {
    return request.get('api/machiningTeacher/history', params, 'machining.index')
}
export function machiningTeacherhistoryExports(params) {
    return request.get('api/machiningTeacher/historyExports', params, 'machining.index')
}

export function machiningShelf(params) {
    return request.get('api/machiningShelf', params, 'framedelivery.index')
}
export function machiningShelfexports(params) {
    return request.get('api/machiningShelf/exports', params, 'framedelivery.index')
}
export function machiningprints(params) {
    return request.post('api/machiningApi/prints', params, 'framedelivery.index')
}

export function machiningTeacherexports(params) {
    return request.get('api/machiningTeacher/exports', params, 'framedelivery.index')
}
export function machiningTeacher(params) {
    return request.get('api/machiningTeacher', params, 'framedelivery.index')
}

export function machiningShow(id) {
    return request.get(`api/machining/${id}`, {}, 'machining.info')
}
export function machiningShows(id) {
    return request.get(`api/services/${id}`, {}, 'machining.info')
}
export function machinfoShows(id) {
    return request.get(`api/services/${id}/machinfo`, {}, 'machining.info')
}
export function updateTime(id, params) {
    return request.post(`api/machining/${id}/updateTime`, params, 'machining.items')
}
export function machiningInfo(id) {
    return request.get(`api/machining/${id}/info`, {}, 'machining.show')
}
export function machiningPush(id, params) {
    return request.post(`api/machining/${id}/push`,params, 'machining.push')
}


export function ZeissSpeed() {
    return request.post(`api/machining/ZeissSpeed`, {}, 'machining.items')
}

export function machiningAbandon(id, params) {
    return request.post(`api/machining/${id}/abandon`, params, 'machining.abandon')
}

export function machiningCancel(id, params) {
    return request.post(`api/machining/${id}/cancel`, params, 'machining.cancel')
}

export function machiningReceived(id, params) {
    return request.post(`api/machining/${id}/received`, params, 'machining.received')
}


export function MachiningOrderSkus(id, params) {
    return request.get(`api/orders/${id}/sku`, params, 'orders.sku')
}

export function machiningCreate(params) {
    return request.post(`api/machining/create`, params, 'machining.create')
}

export function machiningUpdate(id, params) {
    return request.post(`api/machining/${id}/update`, params, 'machining.update')
}

export function machiningService(id, params) {
    return request.post(`api/machining/${id}/service`, params, 'machining.service')
}

export function machiningExports(params) {
    return request.post(`api/machining/exports`, params, 'machining.exports')
}
export function machiningFactory(params) {
    return request.get(`api/machining/factory`, params, 'machining.factory')
}
export function machiningZessship() {
    return request.get(`api/machining/zessShip`, {}, 'machining.zessship')
}



/*
| 加工单售后.
*/
export function servicesIndex(params) {
    return request.get('api/services', params, 'services.index')
}

export function servicesShow(id) {
    return request.get(`api/services/${id}`, {}, 'services.show')
}


export function servicesPostscript(id, params) {
    return request.post(`api/services/${id}/postscript`, params, 'services.postscript')
}

export function servicesBack(id, params) {
    return request.post(`api/services/${id}/send`, params, 'services.send')
}

export function servicesState(id, params) {
    return request.post(`api/services/${id}/state`, params, 'services.state')
}
export function gethuser(id, params) {
    return request.post(`api/services/${id}/huser`, params)
}
export function getzhuser(id, params) {
    return request.post(`api/services/${id}/zhuser`, params)
}
export function servicesExports(params) {
    return request.post(`api/services/exports`, params, 'services.exports')
}
export function servicesdelete(params) {
    return request.post(`api/machiningTeacher/delete`, params, 'services.exports')
}
export function servicesShelfdelete(params) {
    return request.post(`api/machiningShelf/delete`, params, 'services.exports')
}
export function servicesdeleteTeacher(params) {
    return request.post(`api/machiningTeacher/deleteTeacher`, params, 'services.exports')
}


export function servicesTags(params) {
    return request.get(`api/servicesTags/index`, params, 'services.exports')
}

export function getcreate(params) {
    return request.post(`api/servicesTags/create`, params, 'services.exports')
}

export function getdelete(params) {
    return request.post(`api/servicesTags/delete`, params, 'services.exports')
}

/*
| 权限相关操作.
*/
export function permissionsIndex(params) {
    return request.get('api/permissions', params, 'permissions.index')
}

export function permissionsStore(params) {
    return request.post(`api/permissions`, params, 'permissions.store')
}

export function permissionsUpdate(id, params) {
    return request.put(`api/permissions/${id}`, params, 'permissions.update')
}

export function permissionsGrant() {
    return request.get(`api/permissions/grant`, {}, 'permissions.grant')
}

/*
| 角色相关操作.
*/
export function rolesIndex(params) {
    return request.get('api/roles', params, 'roles.index')
}

export function rolesStore(params) {
    return request.post(`api/roles`, params, 'roles.store')
}

export function rolesUpdate(id, params) {
    return request.put(`api/roles/${id}`, params, 'roles.update')
}

export function rolesGrant(id, params) {
    return request.post(`api/roles/${id}/grant`, params, 'roles.grant')
}

/*
| SKU相关操作.
*/
export function skusIndex(params) {
    return request.get('api/skus', params, 'skus.index')
}

export function skusUpdate(id, params) {
    return request.put(`api/skus/${id}`, params, 'skus.update')
}
export function skusCreate(params) {
    return request.post(`api/skus`, params, 'skus.store')
}

export function skusCopy(id) {
    return request.post(`api/skus/${id}/copy`, {}, 'skus.copy')
}
export function updateDegrees(id, params) {
    return request.post(`api/orders/${id}/updateDegrees`, params)
}
export function createDegrees(id, params) {
    return request.post(`api/orders/${id}/createDegrees`, params)
}
export function createImg(id, params) {
    return request.post(`api/orders/${id}/createImg`, params)
}


export function skusExports(params) {
    return request.post(`api/skus/exports`, params, 'skus.exports')
}

export function skusNumber(params) {
    return request.post(`api/skus/number`, params, 'skus.number')
}

export function skusBrand(params) {
    return request.post(`api/skus/brand`, params, 'skus.brand')
}
export function skusCategory(params) {
    return request.post(`api/skus/category`, params, 'skus.brand')
}

/*
| 授权门店相关操作.
*/
export function storesIndex(params) {
    return request.get('api/stores', params, 'stores.index')
}

export function storesStore(params) {
    return request.post(`api/stores`, params, 'stores.store')
}

export function storesUpdate(id, params) {
    return request.put(`api/stores/${id}`, params, 'stores.update')
}

export function storesRedirect(id) {
    return request.post(`api/stores/${id}/redirect`, {}, 'stores.redirect')
}

/*
| 爬去记录相关操作.
*/
export function syncIndex(params) {
    return request.get('api/sync', params, 'sync.index')
}

export function customersphone(id, params) {
    return request.post(`/api/customers/${id}/phone`, params, 'sync.index')
}

/*
| 管理员相关操作.
*/
export function usersIndex(params) {
    return request.get('api/users', params, 'users.index')
}

export function usersStore(params) {
    return request.post(`api/users`, params, 'users.store')
}

export function usersUpdate(id, params) {
    return request.put(`api/users/${id}`, params, 'users.update')
}

/*
| 登录相关操作.
*/
export function basicsSignin(params) {
    return request.post('api/basics/signin', params, 'basics.signin')
}

export function basicsSignout() {
    return request.post('api/basics/signout', {}, 'basics.signout')
}

export function basicsQrcode() {
    return request.post('api/basics/qrcode', {}, 'basics.qrcode')
}

export function basicsMonitor(params) {
    return request.post('api/basics/monitor', params, 'basics.monitor')
}

/*
| 消费者客户相关操作.
*/
export function customersIndex(params) {
    return request.get('api/customers', params, 'customers.index')
}

export function customersMap(params) {
    return request.get('api/customers/map', params, 'customers.map')
}

export function customersExports(params) {
    return request.post(`api/customers/exports`, params, 'customers.exports')
}

/*
| 公共相关操作.
*/
export function citys() {
    return request.get('api/storage/city.json', {}, null)
}

export function storage() {
    return request.get('api/storage/storage.json', {}, null)
}

export function clearCache() {
    return request.get('api/basics/clear', {}, null)
}

/*
| 营销短信相关操作.
*/
export function smsIndex() {
    return request.get('api/sms', {}, 'sms.index')
}

export function smsSend() {
    return request.post('api/sms', {}, 'sms.send')
}

/*
| 售后服务相关操作.
*/
export function salesIndex(params) {
    return request.get('api/sales', params, 'sales.index')
}

export function salesApproval(id, params) {
    return request.post(`api/sales/${id}/approval`, params, 'sales.pproval')
}

export function salesShow(id) {
    return request.get(`api/sales/${id}`, {}, 'sales.show')
}

export function salesExports(params) {
    return request.post(`api/sales/exports`, params, 'sales.exports')
}
/*
| 标签相关操作.
*/
export function tagsIndex() {
    return request.get('api/tags', {}, 'tags.index')
}

export function tagsStore(params) {
    return request.post(`api/tags`, params, 'tags.store')
}

export function tagsDestroy(id) {
    return request.delete(`api/tags/${id}`, {}, 'tags.destroy')
}

/*
| 财务应付账款相关操作.
*/
export function payablesIndex(params) {
    return request.get('api/payables', params, 'payables.index')
}


export function payablesShow(id) {
    return request.get(`api/payables/${id}`, {}, 'payables.show')
}


export function payablesCancel(id) {
    return request.post(`api/payables/${id}/cancel`, {}, 'payables.cancel')
}

export function payablesRecovery(id) {
    return request.post(`api/payables/${id}/recovery`, {}, 'payables.recovery')
}

export function payablesExport(params) {
    return request.post(`api/payables/export`, params, 'payables.export')
}
export function financesync(params) {
    return request.post('api/machining/sync', params)
}

/*
| 财务售后账款相关操作.
*/
export function discardsIndex(params) {
    return request.get('api/discards', params, 'discards.index')
}

export function discardsExport(params) {
    return request.post(`api/discards/export`, params, 'discards.export')
}

/*
| 财务统计相关操作.
*/
export function financeIndex() {
    return request.get('api/finance', {}, 'finance.index')
}

/*
| 供货商相关操作.
*/
export function suppliersIndex(params) {
    return request.get('api/suppliers', params, 'suppliers.index')
}

export function suppliersStore(params) {
    return request.post(`api/suppliers`, params, 'suppliers.store')
}

export function suppliersUpdate(id, params) {
    return request.put(`api/suppliers/${id}`, params, 'suppliers.update')
}



export function printss(id, params) {
    return request.post(`api/orders/${id}/print`, params)
}
export function orderPrint(id, params) {
    return request.post(`api/orders/${id}/orderPrint`, params)
}

export function customerid(params,id) {
    return request.post(`api/orders/${id}/customer`,params, {})
}

export function getmaterial(id, params) {
    return request.post(`api/orders/${id}/item`, params)
}
export function getcost(id, params) {
    return request.post(`api/orders/${id}/cost`, params)
}

export function getItemOther(params) {
    return request.get(`api/orders/itemOther`, params)
}

export function getProductList(id, params) {
    return request.get(`api/skus/${id}/item`, params)
}
export function getbindsList(params) {
    return request.post(`api/orders/binds`, params)
}

export function getProductLists(params) {
    return request.get(`api/items`, params)
}

export function getStatistic(params) {
    return request.get(`api/Statistic/order`, params)
}
export function getsearchs(params) {
    return request.get(`api/search`, params)
}
export function getsyncAll(params) {
    return request.post(`api/orders/syncAll`, params)
}


//订单地域报表 
export function getorderAreaStatistic(params) {
    return request.get(`api/Statistic/orderArea`, params)
}
//订单复购报表
export function getorderRepur(params) {
    return request.get(`api/Statistic/orderRepur`, params)
}
//商品品牌报表
export function getordergoodBrand(params) {
    return request.get(`api/Statistic/goodBrand`, params)
}
//商品销售报表
export function getordergoodSale(params) {
    return request.get(`api/Statistic/goodSale`, params)
}
//客户地域报表
export function getorderuserArea(params) {
    return request.get(`api/Statistic/userArea`, params)
}
//客户新增统计报表
export function getorderuserAdd(params) {
    return request.get(`api/Statistic/userAdd`, params)
}

// api/Statistic/orderArea
// 打印错误

export function creates(params) {
    var remark = {
        remark: params.remark.message
    }
    return request.post('api/loggings/create', remark, 'loggings.create')
}